import React from 'react';
import Layout from 'components/layout';
import PreorderHero from 'components/preorder';

const Preorder = () => (
  <Layout>
    <PreorderHero />
  </Layout>
);

export default Preorder;
