import styled, { css } from 'styled-components';
import Logo from '../../../static/Logo.png';
import HIFGraphic from '../../../static/hif-graphic.jpg';

export const Nav = styled.div`
  margin-top: 50px;
  width: 100%;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  z-index: 2000;
  padding: 0 20px;
  left: 0;
  right: 0;
  box-sizing: border-box;
  .Logo {
    max-width: 218.79px;
    height: 77px;
    background: transparent url(${Logo}) no-repeat;
    background-size: cover;
    text-indent: -9999px;
  }

  @media (max-width: 700px) {
    margin-top: 3vh;
    a:first-child {
      margin-left: 0;
    }
  }
`;

export const BG = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#fff')};
  text-align: center;
  min-height: 840px;

  img.lauvLogo {
    width: 226px !important;
    margin-bottom: 20px;
    display: block;
    margin: 0 auto 20px;
  }

  .hifWrapper {
    width: 100%;
    max-width: 1680px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 30px;
    height: 100vh;

    @media (max-width: 700px) {
      flex-direction: column;
      height: 100%;
      min-height: none;
      padding: 4vh 20px 50px;
    }
  }

  .promoPhoto {
    width: 50%;
    background: transparent url(${HIFGraphic}) no-repeat;
    background-size: contain;
    background-position-y: center;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    @media (max-width: 700px) {
      width: 100%;
    }
  }

  .avail {
    font-family: 'GT America';
    font-weight: 300;
    font-style: italic;
    text-transform: uppercase;
    font-size: 1.8em;
    @media (max-width: 700px) {
      font-size: 6vw;
    }
  }

  .promoLinks {
    width: 50%;
    @media (max-width: 700px) {
      width: 100%;
    }

    img {
      width: 100%;
      max-width: 388px;
    }
  }

  .orderWrapper {
    margin: 20px 0 0 0;
  }

  .orderTitle {
    font-family: 'GT America';
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
  }

  .orderBtns {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    @media (max-width: 700px) {
      flex-wrap: wrap;
    }
    a {
      margin: 0 10px;
      font-family: 'GT America';
      font-weight: bold;
      font-style: italic;
      background: #000;
      border-radius: 30px;
      padding: 10px;
      color: #fff;
      text-decoration: none;
      font-size: 0.7em;
      display: inline-block;
      max-width: 250px;
      width: calc(50% - 30px);
      text-transform: uppercase;
      transition: all 180ms ease-out;
      position: relative;
      img {
        position: absolute;
        left: 14px;
        top: 6px;
        width: auto;
        height: 18px;
      }
      @media (max-width: 700px) {
        width: 100%;
        margin-bottom: 15px;
        max-width: none;
        margin: 0 30px 15px;
        /* 
        color: #000;
        padding: 0; */
      }
      &:hover {
        background: linear-gradient(
          89.91deg,
          #6530cf 0.64%,
          #1032f2 23.92%,
          #83e460 43.07%,
          #efe755 61.69%,
          #df7c36 80.84%,
          #d24237 99.98%
        );
      }
    }
  }
`;

export const Copy = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  max-width: 50%;
  height: 100%;
  padding: 0 70px;
  box-sizing: border-box;
  font-family: 'BDRmono';
  font-size: 1rem;

  @media (max-width: 750px) {
    padding: 0 30px;
    width: 100%;
    max-width: none;
  }
`;

export const Txt = styled.div`
  font-size: 5em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 24px;
  white-space: nowrap;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);

  @media (min-width: 1200px) {
    font-size: 5em;
  }

  @media (max-width: 600px) {
    font-size: 8vw !important;
  }

  @media (max-width: 330px) {
    font-size: 6.4vw;
  }

  ${({ cta }) =>
    cta &&
    css`
      font-family: 'Retro';
      position: absolute;
      bottom: 40px;
      font-size: 22px;

      @media (max-width: 750px) {
        font-size: 4.5vw !important;
      }

      @media (min-width: 1200px) {
        font-size: 22px;
      }
    `}
`;

export const MobilePromo = styled.div`
  display: none;
  @media (max-width: 700px) {
    display: block;
    background: linear-gradient(
      89.91deg,
      #6530cf 0.64%,
      #1032f2 23.92%,
      #83e460 43.07%,
      #efe755 61.69%,
      #df7c36 80.84%,
      #d24237 99.98%
    );
  }

  .orderWrapper {
    padding: 30px 0;
    text-align: center;
    color: #fff;
  }

  .orderTitle {
    font-family: 'GT America';
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
  }

  .orderBtns {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    a {
      margin: 0 10px;
      font-family: 'GT America';
      font-weight: bold;
      font-style: italic;
      background: #000;
      border-radius: 30px;
      padding: 10px;
      color: #fff;
      text-decoration: none;
      font-size: 0.65em;
      display: inline-block;
      max-width: 250px;
      width: calc(50% - 30px);
      text-transform: uppercase;
      transition: all 180ms ease-out;
      @media (max-width: 700px) {
        /* padding: 6px; */
      }
      &:hover {
        background: linear-gradient(
          89.91deg,
          #6530cf 0.64%,
          #1032f2 23.92%,
          #83e460 43.07%,
          #efe755 61.69%,
          #df7c36 80.84%,
          #d24237 99.98%
        );
      }
    }
  }
`;

export const Footer = styled.div`
  background: linear-gradient(
    89.81deg,
    #6530cf 0.64%,
    #1032f2 23.92%,
    #83e460 43.07%,
    #efe755 61.69%,
    #df7c36 80.84%,
    #d24237 99.98%
  );
  height: 100px;
`;

export const Social = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 225px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 750px) {
    width: 100%;
    justify-content: center;
    a {
      margin: 0 25px;
    }
  }

  a {
    margin: 0 10px;
    transition: opacity 180ms ease-out;
    &:hover {
      opacity: 0.7;
    }
  }

  ${({ mobile }) =>
    mobile &&
    css`
      display: flex !important;
      width: 80%;
      margin: 20px auto 0;
    `};
`;

export const SiteLink = styled.a`
  font-family: 'GT America';
  font-weight: bold;
  font-style: italic;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  display: block;
  margin-top: 10px;
`;

export const Products = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #eff1f1;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: transparent url(${({ productImg }) => productImg}) no-repeat;
  background-size: cover;
  margin-bottom: 20px;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const ItemWrapper = styled.div`
  display: block;
  width: calc(100% / 2 - 7.5px);
  text-align: center;
  margin-bottom: 50px;
  box-sizing: border-box;

  img {
    display: block;
  }

  .productName {
    text-align: center;
    margin-bottom: 40px;
    font-family: 'GT America';
    font-weight: bold;
    font-style: italic;
    font-size: 1.2em;
    height: 30px;
  }

  .imgLink {
    display: block;
    margin-bottom: 50px;
  }

  .buyBtn {
    display: inline-block;
    padding: 10px 50px;
    text-align: center;
    font-family: 'GT America';
    font-weight: bold;
    font-style: italic;
    box-sizing: border-box;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    margin: 12px;
    background: linear-gradient(
      89.91deg,
      #6530cf 0.64%,
      #1032f2 23.92%,
      #83e460 43.07%,
      #efe755 61.69%,
      #df7c36 80.84%,
      #d24237 99.98%
    );
    &:hover {
      color: #000;
      background: #fff;
    }

    @media (max-width: 700px) {
      padding: 10px 50px;
      width: 100%;
      margin: 12px 0;
      padding: 6px 10px;
      font-size: 0.8em;
    }
  }
`;
